<template>
  <div id="filePreview" @click="closeFilePreView">
    <div id="closeFilePreView" class="iconfont icon-baseline-close-px"></div>
    <!-- audio -->
    <div
      id="audioPreView"
      class="file-preview-container"
      v-if="fileType === 'audio'"
    >
      <audio
        id="audioView"
        ref="audio"
        :src="fileSrc"
        controls
        autoplay
      ></audio>
    </div>
    <!-- video -->
    <div
      id="videoPreView"
      class="file-preview-container"
      v-loading="loading"
      v-else-if="fileType === 'video'"
    >
      <div id="videoInfo" class="display-flex">
        <div>
          <b>{{ previewFileInfo.fileName }}</b>
          <span>{{ mediaPlayer.status }}</span>
        </div>
        <div class="display-flex flex-start">
          <em>{{ applicationSuffix }}</em>
          <em>{{ mediaPlayer.resolution }}</em>
          <em>{{ mediaPlayer.duration }}s</em>
          <em>{{ fileSize }}</em>
          <i></i>
          <!-- <u class="iconfont icon-download"></u> -->
          <u class="iconfont icon-camera" @click="videoScreenshot"></u>
        </div>
      </div>
      <div id="videoView">
        <video ref="video" :src="fileSrc" controls autoplay></video>
      </div>
    </div>
    <!-- office -->
    <div id="officePreView" class="file-preview-container" v-else>
      <template v-if="officeInfo.off"
        ><iframe
          id="officeIframe"
          ref="office"
          :src="officeInfo.src"
          frameborder="0"
        ></iframe
      ></template>
      <template v-else>
        <div id="embedHeader" class="display-flex">
          <b>{{ previewFileInfo.fileName }}</b>
          <div @click="fileDownload">
            <i class="iconfont icon-download"></i><span>下载</span>
          </div>
        </div>
        <embed
          id="officeEmbed"
          ref="embed"
          :src="officeInfo.src"
          :type="previewFileInfo.fileInfo.type"
        />
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { ossDownload } from "@plugins/aliyunOss";

export default {
  name: "FilePreview",
  computed: {
    fileSrc() {
      return this.previewFileInfo.src;
    },
    officeInfo() {
      let src, off;
      switch (this.applicationSuffix.toLocaleLowerCase()) {
        case "ppt":
        case "pptx":
        case "ppsx":
        case "xls":
        case "xlsx":
        case "doc":
        case "docx":
        case "rtf":
          off = true;
          src = `https://view.officeapps.live.com/op/view.aspx?src=${this.previewFileInfo.src}`;
          break;
        case "js":
        case "css":
        case "less":
        case "scss":
        case "pdf":
          off = false;
          src = this.previewFileInfo.src;
          break;
        default:
          off = false;
          break;
      }
      return { off, src };
    },
    fileType() {
      return this.previewFileInfo.fileType;
    },
    fileSize() {
      return (
        Math.floor((this.previewFileInfo.fileInfo.size / 1024 / 1024) * 10) /
          10 +
        "Mb"
      );
    },
    applicationSuffix() {
      let a = /\.{1}\w+$/.exec(this.previewFileInfo.fileName);
      if (a) {
        a = a[0].replace(/\./, "");
      }
      return a.toLocaleUpperCase();
    },
    ...mapGetters(["previewFileInfo"])
  },
  data() {
    return {
      loading: true,
      mediaPlayer: {
        status: "",
        duration: 0,
        resolution: "0x0"
      }
    };
  },
  methods: {
    playerInit() {
      let media;
      switch (this.fileType) {
        case "audio":
          media = this.$refs.audio;
          break;
        case "video":
          media = this.$refs.video;
          break;
        default:
          return;
      }
      media.onpause = () => {
        this.mediaPlayer.status = "已暂停";
      };
      media.onplay = () => {
        this.mediaPlayer.status = "";
      };
      media.oncanplay = () => {
        this.loading = false;
        this.mediaPlayer.duration = Math.floor(media.duration);
        this.mediaPlayer.resolution = `${media.videoWidth}x${media.videoHeight}`;
      };
      media.onerror = () => {
        this.loading = false;
        let message;
        switch (media.error.code) {
          case 4:
            message = "媒体文件格式不支持，无法播放";
            break;
          case 3:
            message = "媒体文件解码失败";
            break;
          case 1:
            message = "资源获取被中止";
            break;
        }
        this.$notify.error({
          title: "警告",
          message
        });
      };
    },
    closeFilePreView({ target }) {
      switch (target.id) {
        case "filePreview":
        case "closeFilePreView":
          this.$UpdataVuexState({ key: "showFilePreview", data: false });
          break;
      }
    },
    videoScreenshot() {
      this.$notify({
        title: "再等等",
        message: "以后啥都有",
        type: "warning"
      });
    },
    fileDownload() {
      location.href = ossDownload(this.previewFileInfo.fileInfo.name, {
        "content-disposition": `attachment;filename=${this.previewFileInfo.fileName}`
      });
    },
    ...mapMutations(["$UpdataVuexState"])
  },
  mounted() {
    this.playerInit();
  }
};
</script>
<style lang="scss" scoped>
#filePreview {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
#closeFilePreView {
  position: absolute;
  z-index: 2;
  right: 30px;
  top: 30px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #999999;
  transition: color 0.3s;
  font-size: 32px;
  cursor: pointer;
  &:hover {
    color: #ffffff;
  }
}
.file-preview-container {
  flex: none;
}
#audioPreView {
  width: 500px;
  height: 60px;
}
#videoPreView {
  max-width: 90vw;
  max-height: 90vh;
  background-color: rgba(0, 0, 0, 0.79);
}
#videoPreView,
#officePreView {
  box-shadow: 0 2px 6px 0 rgba(185, 185, 185, 0.5);
  border: 1px solid #dbdee6;
  border-radius: 4px;
  overflow: hidden;
}
#officePreView {
  width: 90vw;
  height: 90vh;
  background-color: #ffffff;
}
#officeIframe,
#officeEmbed {
  width: 100%;
  height: 100%;
}
#audioView {
  width: 100%;
  height: 100%;
  outline: none;
}
#videoInfo {
  height: 42px;
  line-height: 42px;
  font-size: 12px;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  b {
    margin-right: 0.5em;
    font-weight: initial;
  }
  span {
    opacity: 0.5;
  }
  em {
    flex: none;
    height: 22px;
    line-height: 22px;
    font-style: initial;
    color: #fff;
    background-color: #444;
    padding: 0 0.5em;
    margin: 0 0.2em;
    border-radius: 4px;
    opacity: 0.5;
    text-align: center;
  }
  i {
    width: 2px;
    height: 22px;
    margin-left: 6px;
    margin-right: 6px;
  }
  u {
    width: 30px;
    height: 22px;
    text-align: center;
    line-height: 22px;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
      background-color: #f5a623;
    }
  }
}
#videoView {
  max-width: 100%;
  video {
    max-width: 100%;
    max-height: calc(90vh - 42px);
  }
}
#embedHeader {
  height: 42px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.07);
  color: #373839;
  padding-left: 12px;
  padding-right: 12px;
  b {
    font-size: 18px;
  }
  .iconfont {
    font-size: 12px;
    margin-right: 6px;
  }
  span {
    font-size: 14px;
  }
  div {
    flex: none;
    padding: 8px 12px;
    text-align: center;
    height: 16px;
    line-height: 16px;
    color: #fff;
    border-color: #f5a623;
    background-color: #f5a623;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      border-color: #e49a22;
      background-color: #e49a22;
    }
  }
}
</style>
